<template>
    <div class="field-type-box">
        <div class="container">
            <!-- 文字 -->
            <div class="field-type-text">
                <div class="field-type-text-title">空间共享类型及说明</div>
                <div class="field-type-text-subtitle">开拓新的服务场地，立即获取场地分红</div>
            </div>
            <!-- 图片 -->
            <div class="field-type-pic">
                <div class="field-type-pic-box"
                    v-for="(item, index) in pic_arr"
                    :key="index">
                    <img :src="require('@/assets/'+item)" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pic_arr: [
                "images/field/publicScene.png",
                "images/field/openCampus.png",
                "images/field/fieldJoin.png",
                "images/field/fieldFamous.png",
            ],
        }
    },
}
</script>

<style scoped>
.field-type-box{
    padding-top: 50px;
    background-color: #fff;
}
/* 文字 */
.field-type-text{
    margin-bottom: 50px;
    text-align: center;
}
.field-type-text-title{
    letter-spacing: 4px;
    font-size: 24px;
    font-weight: bold;
    color: #8d9afc;
    margin-bottom: 10px;
}
.field-type-text-subtitle{
    font-weight: bold;
    color: #909399;
}
/* 图片 */
.field-type-pic{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.field-type-pic-box{
    width: 50%;
    padding: 10px;
}
.field-type-pic-box>img{
    width: 100%;
}
</style>